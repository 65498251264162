import Mock, { Random } from 'mockjs'
export default {
	ApiMenu: Mock.mock({
		code: 200,
		data: {
			list: [
				{
					id: 5,
					name: '后台面板',
					path: '1',
					icon: 'el-icon-s-platform',
					child: [{ id: 10, name: '主控台', path: '/', icon: 'el-icon-menu', child: [] }],
				},
				{
					id: 6,
					name: '商品管理',
					path: '2',
					icon: 'el-icon-s-goods',
					child: [
						{
							id: 13,
							name: '商品管理',
							path: '/goods/list',
							icon: 'el-icon-menu',
							child: [
								{
									id: 10000,
									name: '888888888',
									path: '/goods/list',
									icon: 'el-icon-menu',
									child: [
										
									],
								},
							],
						},
						{ 
							id: 14, 
							name: '分类管理', 
							path: '/category/list', 
							icon: 'el-icon-menu', 
							child: [] 
						},
						{ 
							id: 15, 
							name: '规格管理', 
							path: '/skus/list', 
							icon: 'el-icon-menu', 
							child: [] 
						},
						{ 
							id: 157, 
							name: '优惠券管理', 
							path: '/coupon/list', 
							icon: 'el-icon-menu', 
							child: [] 
						},
					],
				},
				{
					id: 173,

					name: '用户管理',

					path: '3',

					icon: 'el-icon-s-custom',

					child: [
						{ id: 21, name: '用户管理', path: '/user/list', icon: 'el-icon-menu', child: [] },
						{
							id: 22,

							name: '会员等级',

							path: '/level/list',

							icon: 'el-icon-menu',

							child: [],
						},
					],
				},
				{
					id: 7,

					name: '订单管理',

					path: '4',

					icon: 'el-icon-s-order',

					child: [
						{ id: 18, name: '订单管理', path: '/order/list', icon: 'el-icon-menu', child: [] },
						{ id: 17, name: '评论管理', path: '/comment/list', icon: 'el-icon-menu', child: [] },
					],
				},
				{
					id: 8,

					name: '管理员管理',

					path: '5',

					icon: 'el-icon-s-management',

					child: [
						{ id: 25, name: '管理员管理', path: '/manager/list', icon: 'el-icon-menu', child: [] },
						{ id: 27, name: '权限管理', path: '/access/list', icon: 'el-icon-menu', child: [] },
						{ id: 33, name: '角色管理', path: '/role/list', icon: 'el-icon-menu', child: [] },
					],
				},
				{
					id: 9,

					name: '系统设置',

					path: '6',

					icon: 'el-icon-s-tools',

					child: [
						{ id: 23, name: '基础设置', path: '/setting/base', icon: 'el-icon-menu', child: [] },
						{ id: 26, name: '交易设置', path: '/setting/buy', icon: 'el-icon-menu', child: [] },
						{ id: 24, name: '物流设置', path: '/setting/ship', icon: 'el-icon-menu', child: [] },
					],
				},
				{
					id: 177,

					name: '分销模块',

					path: '7',

					icon: 'el-icon-share',

					child: [
						{ id: 178, name: '分销员管理', path: '/distribution/index', icon: 'el-icon-menu', child: [] },
						{ id: 179, name: '分销设置', path: '/distribution/setting', icon: 'el-icon-menu', child: [] },
					],
				},
				{
					id: 172,

					name: '其他模块',

					path: '8',

					icon: 'el-icon-s-help',

					child: [
						{ id: 11, name: '图库管理', path: '/image/list', icon: 'el-icon-menu', child: [] },
						{ id: 149, name: '公告管理', path: '/notice/list', icon: 'el-icon-menu', child: [] },
					],
				},
			],
		},

		message: '获取成功',
	}),
}
