<template>
	<div id="app">
		<router-view />
	</div>

<!--  <dv-full-screen-container>-->
<!--    <div id="app">-->
<!--      <router-view />-->
<!--    </div>-->
<!--  </dv-full-screen-container>-->
</template>
<script>

// const keyCodeMap = {
//     // 91: true, // command
//     61: true,
//     107: true, // 数字键盘 +
//     109: true, // 数字键盘 -
//     173: true, // 火狐 - 号
//     187: true, // +
//     189: true, // -
//   };
//   // 覆盖ctrl||command + ‘+’/‘-’
//   document.onkeydown = function (event) {
//     const e = event || window.event;
//     const ctrlKey = e.ctrlKey || e.metaKey;
//     if (ctrlKey && keyCodeMap[e.keyCode]) {
//       e.preventDefault();
//     } else if (e.detail) { // Firefox
//       event.returnValue = false;
//     }
//   };
//   // 覆盖鼠标滑动
//   document.body.addEventListener('wheel', (e) => {
//     if (e.ctrlKey) {
//       if (e.deltaY < 0) {
//         e.preventDefault();
//         return false;
//       }
//       if (e.deltaY > 0) {
//         e.preventDefault();
//         return false;
//       }
//     }
//   }, { passive: false });
export default {
	data(){
		return {}
	},
	mounted(){

	},
	methods:{

	}
}
</script>
<style lang="less">
html {
  font-size: 62.5%;
}
// @media screen and (min-width: 1440px) {
//   html {
//     font-size: 62.5%; /* 适配2k分辨率屏幕 */
//   }
// }
@import "assets/css/common.css";
@import "assets/css/jiaobiao.css";

</style>
