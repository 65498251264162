import Mock, { Random } from 'mockjs'
export default {
	ApiLogin: Mock.mock({
		code: 200,
		data: {
			list: {
				username: 'admin',
				password: 'admin',
			},
			token: 'token校验通过',
		},

		message: '登录成功',
	}),
}
