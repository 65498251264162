import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/style/index.scss'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'animate.css'
import 'windi.css'

import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
// 全局挂载
Vue.use(VueAwesomeSwiper)



//引入mock
import './mock/index.js'
import dataV from '@jiaminghi/data-view'
Vue.use(dataV)

import vueSeamlessScroll from 'vue-seamless-scroll'
Vue.use(vueSeamlessScroll)

import axios from './axios';
Vue.prototype.$axios = axios

Vue.config.productionTip = false
Vue.use(ElementUI, { size: 'small', zIndex: 3000 });
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
