import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
import layoutStore from './modules/layout'
export default new Vuex.Store({
  state: {
    vuex_tenantId: '1588372597175644162',
    vuex_token: '',
    vuex_previewUrl: 'https://ssyn-beichuan.obs.cn-south-1.myhuaweicloud.com/imgs/',
  },
  getters: {

  },
  mutations: {
  },
  actions: {
  },
  modules: {
    layoutStore
  }
})
