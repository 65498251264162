export default {
	state: {
		// 控制菜单是否收起，默认展开
		isExpand: true,
	},
	mutations: {
		setIsExpand(state) {
			// 改变state
			state.isExpand = !state.isExpand
			// 设置storage
			window.sessionStorage.isExpand = JSON.stringify(state.isExpand) 
		},
		handleSetStatus(state, status) {
			state.isExpand = status
		},
	},
	actions: {
		handleLoadStatus({ commit }) {
			let expand = JSON.parse(window.sessionStorage.isExpand)
			commit('handleSetStatus', expand)
		},
	},
}
